"use client";
import SecondaryButton from "../components/ui/Forms/Button/SecondaryBtn";
import { AnimatePresence, motion } from "framer-motion";
import Head from "next/head";
import { useRouter } from "next/navigation";
import { useState } from "react";

export const dynamic = "force-static";

export default function Custom404() {
  const router = useRouter();
  const [textOpacity, setTextOpacity] = useState(1);
  const [videoScale, setVideoScale] = useState(1);

  const handleClick = () => {
    setTextOpacity(0); // Set text opacity to 0 to fade out before navigating
    setVideoScale(4); // Increase scale of the video
    setTimeout(() => router.push("/"), 1500); // Extend timeout to allow for video scaling animation
  };

  return (
    <>
      <Head>
        <title>404 - You are Lost</title>
        <meta property="og:title" content="404 - You are Lost" key="title" />
      </Head>

      <div className="relative items-center mt-[60px]">
        <AnimatePresence>
          <motion.div
            className="w-full h-full absolute top-0 left-0 overflow-hidden"
            animate={{ scale: videoScale }}
            exit={{ opacity: 0, transition: { duration: 0.5 } }}
            transition={{ duration: 3.0, ease: "easeInOut" }}
          >
            <video
              src="/404/space.mov"
              className="w-full h-full"
              autoPlay
              muted
            />
          </motion.div>
        </AnimatePresence>

        <div
          className="sm:pl-[80px] px-4 md:pt-[100px] relative mx-auto 
        flex h-screen flex-col place-items-center text-center "
        >
          <div className="my-auto flex flex-col items-center ">
            <motion.div
              animate={{ opacity: textOpacity }}
              transition={{ duration: 0.5 }}
              className="text-[120px] uppercase leading-[60px] 
              sm:text-[150px] mb-[50px] font-extrabold hadow-[0_2.8pdrop-sx_1.4px_rgba(0,0,0,1)]"
            >
              404
            </motion.div>

            <motion.p
              className="text-[40px] lg:text-[60px] font-extrabold gradientTextGray"
              animate={{ opacity: textOpacity }}
              transition={{ duration: 0.5 }}
            >
              Don&rsquo;t Panic.
            </motion.p>

            <motion.div
              animate={{ opacity: textOpacity }}
              transition={{ duration: 0.5 }}
              className="w-[220px] mx-auto mt-4"
            >
              <SecondaryButton onClick={handleClick}>Go Home</SecondaryButton>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
}
